import React from 'react';
import PropTypes from 'prop-types';
import Paragraphs from '../generic/Paragraphs';

if (process.env.CLIENT) {
  require('./Description.scss'); // eslint-disable-line global-require
}

const Description = ({ description }) => (
  <div className="technique-description__wrap gel-wrap">
    <div className="gel-layout gel-layout--center">
      <div className="gel-layout__item gel-body-copy technique-page__description">
        <Paragraphs text={description} className="technique-page__description-para" />
      </div>
    </div>
  </div>
);

Description.propTypes = {
  description: PropTypes.string.isRequired,
};

export default Description;
