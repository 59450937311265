/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { videoShape } from './shapes';
import { addVideo as addVideoAction } from '../schema/actions';
import Image, { imageSizes } from './Image';

if (process.env.CLIENT) {
  require('./Video.scss'); // eslint-disable-line global-require
}

const getBumpSettings = ({
  additionalUIOptions,
  counterName,
  durationSeconds,
  imageUrl,
  versionPid,
}) => ({
  appName: 'food',
  appType: 'responsive',
  counterName,
  maintainTheLock: false,
  product: 'food',
  preferHtmlOnMobile: true,
  superResponsive: true,
  overrideHoldingImage: imageUrl,
  playlistObject: {
    items: [
      {
        versionID: versionPid,
        duration: durationSeconds,
      },
    ],
  },
  ui: {
    colour: '#268F3A', // Mint Green
    foreColour: '#fff',
    embed: {
      enabled: false,
    },
    poster: {
      availableWidths: imageSizes.join(','),
    },
    ...additionalUIOptions,
  },
});

export class Video extends Component {
  element = React.createRef();

  constructor(props) {
    super(props);

    const { description, duration, title, updatedAt, versionPid } = this.props;

    this.props.addVideo({
      description,
      duration,
      image: this.getImageUrl(),
      title,
      updatedAt,
      versionPid,
    });
  }

  componentDidMount() {
    const { additionalUIOptions, counterName, durationSeconds, versionPid } = this.props;

    this.initMediaPlayer(
      this.element.current,
      getBumpSettings({
        additionalUIOptions,
        counterName,
        durationSeconds,
        imageUrl: this.getImageUrl(),
        versionPid,
      })
    );
  }

  getImageUrl = () => {
    const { cdnUrl, image, ichefBasePath } = this.props;
    if (isEmpty(image)) {
      return `${cdnUrl}images/non-spriteable-images/bbc_placeholder.png`;
    }
    return /^http/.test(image) ? image : `${ichefBasePath}${image}`;
  };

  initMediaPlayer = (element, settings) => {
    window.require(['bump-4'], bump => {
      bump.player(element, settings).load();
    });
  };

  render() {
    return (
      <div className="video-wrapper">
        <Image src={this.getImageUrl()} isLazy={false} />
        <div className="video-wrapper__video" ref={this.element} />
      </div>
    );
  }
}

Video.defaultProps = {
  additionalUIOptions: {},
  duration: undefined,
  durationSeconds: undefined,
  image: undefined,
};

Video.propTypes = videoShape;

export default connect(
  ({ pageReducer }) => ({
    cdnUrl: pageReducer.cdnUrl,
    counterName: pageReducer.reverb.name,
    ichefBasePath: pageReducer.ichefBasePath,
  }),
  {
    addVideo: addVideoAction,
  }
)(Video);
