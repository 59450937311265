import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import upperFirst from 'lodash/upperFirst';
import pick from 'lodash/pick';
import MetaProperties from '../generic/MetaProperties';
import ContentSeparator from '../generic/ContentSeparator';
import Description from './Description';
import Paragraphs from '../generic/Paragraphs';
import PageHeader, { Header2 } from '../generic/PageHeader';
import StandardCollection from '../promo/collections/StandardCollection';
import Video from '../generic/Video';
import { promoShape } from '../promo/shapes';
import { videoShape } from '../generic/shapes';
import AzPage from '../generic/aToZ/Main';

if (process.env.CLIENT) {
  require('./Page.scss'); // eslint-disable-line global-require
}

export const Page = ({
  description,
  difficulty,
  equipment,
  id,
  keyRecipe,
  relatedRecipes,
  title,
  video,
  azCounts,
  activeTab,
  letter,
  page,
  totalCount,
}) => {
  const baseUrl = `/food/techniques/${id}`;

  return (
    <>
      <MetaProperties title={title} />
      <PageHeader title={title} />
      <div className="gel-wrap technique-page__hero-wrap">
        <div className="gel-layout gel-layout--center technique-page__hero-container">
          <div className="gel-layout__item gel-10/12@l technique-page__hero">
            <Video additionalUIOptions={{ cta: { mode: 'duration' } }} {...video} />
          </div>
        </div>
      </div>

      <div className="technique-page-skill__wrap gel-wrap">
        <div className="gel-layout gel-layout--center">
          <div className="gel-layout__item gel-10/12@l">
            <div className="gel-wrap">
              <div className="gel-layout technique-page-skill-equipment__container">
                <div className="gel-layout__item gel-1/1 gel-1/4@m gel-1/2@l technique-page-skill-equipment__left">
                  <h2 className="gel-great-primer-bold technique-page-skill__header">
                    Skill level
                  </h2>
                  <p>{upperFirst(difficulty)}</p>
                </div>
                {equipment && equipment.length > 0 && (
                  <div className="gel-layout__item gel-1/1 gel-3/4@m gel-1/2@l technique-page-skill-equipment__right">
                    <h2 className="gel-great-primer-bold technique-page-equipment__header">
                      Equipment you will need for this technique
                    </h2>
                    <Paragraphs
                      text={equipment}
                      className="gel-pica technique-page__equipment"
                      modifyParagraph={upperFirst}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContentSeparator />

      <Description description={description} />

      {(keyRecipe || (relatedRecipes && relatedRecipes.length > 0)) && (
        <>
          {keyRecipe && (
            <div className="technique-page__key-recipe__container food-body__grey-background">
              <StandardCollection
                id={`technique-${id}-key-recipe`}
                containerClassName="technique-page__key-recipe__collection"
                promos={[keyRecipe]}
                title="See recipe in full"
              />
              <ContentSeparator />
            </div>
          )}
          {relatedRecipes && relatedRecipes.length > 0 && (
            <>
              <Header2 title="Recipes using this technique" anchor="recipes" />
              <AzPage
                azCounts={azCounts}
                baseUrl={baseUrl}
                activeTab={activeTab}
                entity="recipes"
                letter={letter}
                page={page}
                promos={relatedRecipes}
                promoContainerClassName="technique-page"
                anchor="recipes"
                totalCount={totalCount}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

Page.propTypes = {
  description: PropTypes.string.isRequired,
  difficulty: PropTypes.string.isRequired,
  equipment: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  keyRecipe: PropTypes.shape(promoShape),
  relatedRecipes: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
  title: PropTypes.string.isRequired,
  video: PropTypes.shape(videoShape).isRequired,
  azCounts: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  activeTab: PropTypes.oneOf(['all', 'featured']),
  letter: PropTypes.string,
  page: PropTypes.number,
  totalCount: PropTypes.number.isRequired,
};

Page.defaultProps = {
  keyRecipe: null,
  activeTab: 'featured',
  azCounts: {},
  letter: 'a',
  page: 1,
};

export default connect(state => ({
  ...pick(state.techniqueReducer, [
    'description',
    'difficulty',
    'equipment',
    'id',
    'keyRecipe',
    'relatedRecipes',
    'title',
    'video',
    'azCounts',
    'activeTab',
    'letter',
    'page',
    'totalCount',
  ]),
}))(Page);
